import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Flex } from 'grid-styled'
import CTA from '../../components/CTA'
import GalleryContainer from '../../components/GalleryContainer'
import GalleryImage from '../../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
} from '..'
import Form from '../../components/Form'
import { Text, GallerySection, Content } from '../../components/styles'
import Button from '../../components/Button'
import ContentCard from '../../components/ContentCard'
import Hero from '../../components/Hero'
import { Video } from '../../components/procedures'

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Flat-Skillion Insulation Patio | Just Patios</title>
      <meta
        name="description"
        content="Insulated patio builders servicing Brisbane and the Sunshine Coast regions. Custom residential skillion insulated patios for your home. Contact us today for an obligation free design & quote."
      />
    </Helmet>

    <Content style={{ paddingTop: 150 }}>
      <h1>Flat / Skillion - Insulated Patio</h1>

      <h4>
        One of Queensland's most popular patio designs with extra insulation. A
        great patio base for future possibilities.
      </h4>
      <p>
        This roofing material is substantially cooler in summer and is a step up
        from our non-insulated range. It offers a ceiling like appearance
        underside with the added benefit of being able to install lights &
        ceiling fans. This option is great if you are considering making the
        area into a room at a later date. It has all the options of the non
        insulated roofing, with long spans & reducing the need for beams &
        rafters. This leaves you with a very low maintenance, high quality patio
        area with a range of colours to match your house.
      </p>

      <Link to="/contact-us#form">
        <Button inverted={true}>Request Your Free Quote Today!</Button>
      </Link>
    </Content>

    <GalleryContainer>
      {data.gallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          const arr = x.node.childImageSharp.fixed.src.split('/')
          const number = parseInt(arr[arr.length - 1].split('.')[0])
          const arr2 = y.node.childImageSharp.fixed.src.split('/')
          const number2 = parseInt(arr2[arr2.length - 1].split('.')[0])

          return number < number2 ? -1 : 1
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return false
          }
          const arr = image.node.childImageSharp.fixed.src.split('/')
          const name = arr[arr.length - 1].split('.')[0]
          if (name === 'cover') {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fixed={image.node.childImageSharp.fixed} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>

    <VideoContainer style={{ marginTop: -100 }}>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0,
          },
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query SkillionInsulatedQuery {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/patios/insulated" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
